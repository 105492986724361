<template>
  <div class="card">
    <div class="leftcontent">
      <img src="" alt="" class="product-avator" />
    </div>

    <div class="rightcontent">
      <div class="product-name">
        123好久不见被看见你肯努力肯努力努力努力科纳克里
      </div>
      <div class="desc">
        <span class="look">123</span><span class="price">123</span>
      </div>
    </div>
    <div class="posab">待发货<span style="margin-left: 2px">></span></div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DeliverCard extends Vue {}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  background: white;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-left: 10px;
  .leftcontent {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    .product-avator {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .posab {
    position: absolute;
    right: 12px;
    bottom: 12px;
    color: #f83737;
    font-size: 12px;
  }
  .rightcontent {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    height: 80px;
    justify-content: space-between;
    flex: 1;
    .product-name {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 70%;
      font-size: 15px;
    }
    .desc {
      font-size: 14px;
      .look {
        color: #878e99;
      }
      .price {
        color: #f83737;
        font-weight: 800;
        margin-left: 5px;
      }
    }
  }
}
</style>
