<template>
  <div>
    <div class="top" style="position: relative">
      <div class="top_item" @click="toCombine" style="margin-left: 12px">
        <img
          class="top_item_img"
          src="https://cdn.vgn.cn/static/recyle/%E5%9B%BE%E6%A0%87-%E5%90%88%E6%88%90%E5%8D%87%E7%BA%A7%402x.png"
          alt=""
        />
        <div class="top_item_title">合成升级</div>
      </div>
      <div class="top_item" @click="toCombineSelf">
        <img
          class="top_item_img"
          src="https://cdn.vgn.cn/static/recyle/%E5%9B%BE%E6%A0%87%EF%BC%8F%E5%90%88%E6%88%90%E8%87%AA%E9%80%89%402x%20(1).png"
          alt=""
        />
        <div class="top_item_title">合成自选</div>
      </div>
      <div class="top_item" style="position: relative" @click="tosell">
        <img
          class="top_item_img"
          src="https://cdn.vgn.cn/static/recyle/%E5%9B%BE%E6%A0%87%EF%BC%8F%E5%90%88%E6%88%90%E8%87%AA%E9%80%89%402x%20(4).png"
          alt=""
        />
        <div class="top_item_title">挂售</div>
      </div>
      <div class="top_item" style="position: relative" @click="toRecyle">
        <img
          class="top_item_img"
          src="https://cdn.vgn.cn/static/recyle/%E5%9B%BE%E6%A0%87%EF%BC%8F%E5%90%88%E6%88%90%E8%87%AA%E9%80%89%402x.png"
          alt=""
        />
        <div class="top_item_title">回收</div>
      </div>
      <div class="supply-detail-mysupply" @click="toSupplyDetail">
        <div>补</div>
        <div>给</div>
        <div>明</div>
        <div>细</div>
      </div>
    </div>

    <tabs class="tab" v-model="active" :ellipsis="false" sticky swipeable>
      <tab title="全部">
        <div style="min-height: 600px" ref="supplyBody">
          <template v-if="supplyItems.length > 0">
            <List
              v-model="loading0"
              :finished="finished0"
              finished-text="没有更多了"
              @load="load0"
              :immediate-check="false"
            >
              <div class="supplyall">
                <my-supply-item
                  style="margin-right: 3%; margin-bottom: 20px; width: 30%"
                  v-for="(item, index) in supplyItems"
                  :key="index"
                  :cover="item.cover"
                  :is_gold_vip="item.is_gold_vip"
                  :bazaar_lock="item.bazaar_lock"
                  :title="item.title"
                  :wideHigh="wideHigh"
                  :recycle="item.support_recycle"
                  :is_seal="item.is_seal"
                  :character="item.character.value"
                  :is-entity="item.supply_type === 2"
                  @click="
                    handleOpenPage(
                      item.id,
                      item.supply_type,
                      item.bazaar_lock,
                      item.is_seal
                    )
                  "
                />
              </div>
            </List>
          </template>
          <Empty class="empty" v-else tip="补给箱空空如也" />
        </div>
      </tab>
      <tab title="未拆封">
        <div style="min-height: 600px" ref="supplyBody">
          <template v-if="sealCard.length > 0">
            <List
              v-model="loading1"
              :finished="finished1"
              finished-text="没有更多了"
              @load="load1"
              :immediate-check="false"
            >
              <div class="supplyall">
                <my-supply-item
                  style="margin-right: 3%; margin-bottom: 20px; width: 30%"
                  v-for="(item, index) in sealCard"
                  :key="index"
                  :cover="item.cover"
                  :title="item.title"
                  :is_gold_vip="item.is_gold_vip"
                  :bazaar_lock="item.bazaar_lock"
                  :wideHigh="wideHigh"
                  :is_seal="item.is_seal"
                  :character="item.character.value"
                  :is-entity="item.supply_type === 2"
                  @click="
                    handleOpenPage(
                      item.id,
                      item.supply_type,
                      item.bazaar_lock,
                      item.is_seal
                    )
                  "
                />
              </div>
            </List>
          </template>
          <Empty class="empty" v-else tip="这里还没有未拆封~" />
        </div>
      </tab>

      <tab title="已拆封">
        <div style="min-height: 600px" ref="supplyBody">
          <template v-if="unsealCard.length > 0">
            <List
              v-model="loading2"
              :finished="finished2"
              finished-text="没有更多了"
              @load="load2"
              :immediate-check="false"
            >
              <div class="supplyall">
                <my-supply-item
                  style="margin-right: 3%; margin-bottom: 20px; width: 30%"
                  v-for="(item, index) in unsealCard"
                  :key="index"
                  :cover="item.cover"
                  :title="item.title"
                  :is_gold_vip="item.is_gold_vip"
                  :bazaar_lock="item.bazaar_lock"
                  :wideHigh="wideHigh"
                  :is_seal="item.is_seal"
                  :character="item.character.value"
                  :is-entity="item.supply_type === 2"
                  @click="
                    handleOpenPage(
                      item.id,
                      item.supply_type,
                      item.bazaar_lock,
                      item.is_seal
                    )
                  "
                />
              </div>
            </List>
          </template>
          <Empty class="empty" v-else tip="这里还没有已拆封~" />
        </div>
      </tab>
      <tab class="tabtop" title="待发货">
        <delivery-list :type="1" v-if="true" style="top: 0px" />
      </tab>
      <tab class="tabtop" title="已发货">
        <delivery-list :type="2" v-if="true" style="top: 0px" />
      </tab>
      <tab title="挂售中">
        <div style="min-height: 600px" ref="supplyBody">
          <template v-if="saleCard.length > 0">
            <List
              v-model="loading3"
              :finished="finished3"
              finished-text="没有更多了"
              @load="load3"
              :immediate-check="false"
            >
              <div class="supplyall">
                <my-supply-item
                  style="margin-right: 3%; margin-bottom: 20px; width: 30%"
                  v-for="(item, index) in saleCard"
                  :key="index"
                  :cover="item.cover"
                  :is_gold_vip="item.is_gold_vip"
                  :title="item.title"
                  :bazaar_lock="item.bazaar_lock"
                  :wideHigh="wideHigh"
                  :is_seal="item.is_seal"
                  :character="item.character.value"
                  :is-entity="item.supply_type === 2"
                  @click="
                    handleOpenPage(
                      item.id,
                      item.supply_type,
                      item.bazaar_lock,
                      item.is_seal
                    )
                  "
                />
              </div>
            </List>
          </template>
          <Empty class="empty" v-else tip="这里还没有挂售中~" />
        </div>
      </tab>
    </tabs>
    <router-link
      v-if="!flutter"
      :to="{ name: 'Welfare' }"
      class="more_welfare_btn"
      style="z-index: 99"
    />
  </div>
</template>

<script>
import { Component, Vue, Ref } from "vue-property-decorator";
import { Tab, Tabs, List, Toast } from "vant";
import Empty from "@/components/EmptyBox.vue";
import MySupplyItem from "@/components/MySupplyItemNew.vue";
import DeliverCard from "@/views/supply/components/DeliverCard.vue";
import ProductCard from "@/views/supply/components/ProductCard.vue";
import DeliveryList from "@/views/mine/components/DeliveryList.vue";
import { IMineSupply, VoucherApi } from "@/api/voucher.api";
import BlindBoxApi, { Deliver } from "@/api/blind-box.api";
import InteractionLib from "@/utils/interaction.lib";
import { observer } from "@/utils/common.util";

@Component({
  components: {
    DeliveryList,
    List,
    MySupplyItem,
    ProductCard,
    Empty,
    Tab,
    Tabs,
    DeliverCard,
  },
})
export default class MySupply extends Vue {
  @Ref("supplyBody") supplyBodyRef;
  supply = [];
  active = 0;
  supplyItems = [];
  sealCard = [];
  unsealCard = [];
  saleCard = [];

  get flutter() {
    return InteractionLib.isApp();
  }

  loading0 = false;
  finished0 = false;
  page0 = 1;
  page_size0 = 20;
  loading1 = false;
  finished1 = false;
  page1 = 1;
  page_size1 = 20;
  loading2 = false;
  finished2 = false;
  page2 = 1;
  page_size2 =20;

  loading3 = false;
  finished3 = false;
  page3 = 1;
  page_size3 = 20;

  async load0() {
    let res = await VoucherApi.getNewSumSupply(
      0,
      ++this.page0,
      this.page_size0
    );
    this.loading0 = false;
    if (res.length < 10) {
      this.finished0 = true;
    }
    res.forEach((item) => {
      this.supplyItems.push(item);
    });
  }
  toRecyle(){
     if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/recyle`);
    }
    this.$router.push({name:'recyle'})
  }
  async load1() {
    let res = await VoucherApi.getNewSumSupply(
      1,
      ++this.page1,
      this.page_size1
    );
    this.loading1 = false;
    if (res.length < 10) {
      this.finished1 = true;
    }
    res.forEach((item) => {
      this.sealCard.push(item);
    });
  }

  async load2() {
    let res = await VoucherApi.getNewSumSupply(
      3,
      ++this.page2,
      this.page_size2
    );
    this.loading2 = false;
    if (res.length < 10) {
      this.finished2 = true;
    }
    res.forEach((item) => {
      this.unsealCard.push(item);
    });
  }

  async load3() {
    let res = await VoucherApi.getNewSumSupply(
      2,
      ++this.page3,
      this.page_size3
    );
    this.loading3 = false;
    if (res.length < 10) {
      this.finished3 = true;
    }
    res.forEach((item) => {
      this.saleCard.push(item);
    });
  }

  handleOpenPage(id, type, bazaar_lock, is_seal) {
    if (type == 2 && is_seal == false) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(
          `${location.origin}/my-delivery-record/${id}`
        );
      }

      this.$router.push({
        name: "MyDeliveryRecordDetail",
        params: { id },
      });
    }
    if (bazaar_lock == 1) {
      Toast.fail("该补给正在挂售中");
      setTimeout(() => {
        Toast.clear();
      }, 2000);
      return;
    }
    if (InteractionLib.isApp()) {
      if (type === 2) {
        return InteractionLib.openWebView(
          `${location.origin}/my-voucher/${id}/entity`
        );
      } else {
        return InteractionLib.openWebView(
          `${location.origin}/my-voucher/${id}`
        );
      }
    }
    const name = type === 2 ? "MyVoucherEntityDetail" : "MyVoucherDetail";
    this.$router.push({
      name,
      params: { id: String(id) },
      query: { time: String(this.getNow()) },
    });
  }

  wideHigh = "";

  popTips(item) {
    if (item == 1) {
      console.log(item);
    }
  }

  mounted() {
    observer("refreshInit", () => {
      this.init();
    });
  }

  async init() {
    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = (clientWidth || offsetWidth) / 3 - 20 + "px";
    this.active = Number(this.$route.query.num);
    this.supplyItems = await VoucherApi.getNewSumSupply(0);
    this.sealCard = await VoucherApi.getNewSumSupply(1);
    this.saleCard = await VoucherApi.getNewSumSupply(2);
    this.unsealCard = await VoucherApi.getNewSumSupply(3);
  }

  async created() {
    this.init();
    InteractionLib.setWebViewTitle("我的补给");
  }

  getNow() {
    return Math.ceil(Date.now() / 1000);
  }

  toCombine() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/synthetic`);
    }
    this.$router.push({ path: "/synthetic" });
  }

  toCombineSelf() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/optional`);
    }
    this.$router.push({ path: "optional" });
  }

  tosell() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sell`);
    }
    this.$router.push({ path: "/sell" });
  }

  toSupplyDetail() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/supply-detail`);
    }
    this.$router.push({ name: "supplydetal" });
  }
}
</script>

<style lang="scss" scoped>
.supply-detail-mysupply {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba($color: #000000, $alpha: 0.07);
  font-size: 10px;
  color: #999;
  position: absolute;
  height: 77px;
  width: 28px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 8px 0 0 8px;
  font-weight: 800;
}

.moreluck {
  position: fixed;
  left: 0;
  top: 80%;
}

.empty {
  top: 100px;
}

.delivery-list {
  padding: 12px;
}

.supplyall {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px;
}

.top {
  padding: 20px 0;
  display: flex;
  background: white;
  border-bottom: 1px #f5f5f5 solid;
  align-items: center;

  .tabtop {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .top_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 16px;

    .top_item_img {
      width: 60px;
      height: 50px;
    }

    .top_item_title {
      margin-top: 10px;
      font-size: 12px;
    }

    .top_item_unknow_title {
      margin-top: 10px;
      font-size: 12px;
      color: #969696;
    }
  }
}

.more_welfare_btn {
  position: fixed;
  background-image: url("~@/assets/svg/more_welfare_1.svg");
  width: 79px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  bottom: 120px;
  left: -2px;
  z-index: 20;
}

.tab {
  ::v-deep {
    .van-tab {
      font-weight: 800;
    }

    .van-tab {
      color: #ccc;
    }

    .van-tab--active {
      color: black;
      font-weight: 800;
    }

    .van-tabs__line {
      width: 15px;
      background: black;
      height: 2px;
      top: 65%;
    }
  }
}

.newplay {
  background: url("https://cdn.vgn.cn/static/new/dot.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  width: 50px;
  height: 18px;
  font-size: 10px;
  color: white;
  position: absolute;
  top: -14px;
}
</style>
