<template>
  <div class="__flex_column">
    <img src="" alt="" />
    <div class="title">12312213312</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.__flex_column {
  display: flex;
  flex-direction: column;
  img {
    width: 108px;
    height: 108px;
  }
  .title {
    font-size: 14px;
    font-weight: 800;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
